.container {
    width: 90%;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .container {
        width: 95%;
    }
}