@import url('https://fonts.googleapis.com/css?family=Manrope:400,500,700');


:root {
    --brand_color: #00bcd4;
}

* {
    box-sizing: border-box;
}

html {
    font-family: 'Manrope-Regular', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'NeueHaasDisplay-Bold', sans-serif;
    font-weight: bold;
}

.app {
    width: 100%;
    padding: 1rem;
    background-color: white;
    margin: 3rem auto;
}

nav a.active {
    color: var(--brand_color) !important;
    font-weight: semi-bold !important;
    border-bottom: 1px solid var(--brand_color) !important;
}