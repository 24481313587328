.header {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    font-size: .8rem;
    box-shadow: 0px 2px 6px 0px rgba(152, 157, 165, 0.4);
}

.nav {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    align-items: center;
}

.active {
    color: #00bcd4 !important;
    font-weight: bold !important;
}

@media screen and (max-width: 1200px) {
    .nav {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .navlinks {
        display: none;
    }

    .btngrp {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .nav {
        display: none;
    }
}